import React from 'react';

import styles from './part-form-input.module.scss';

export default function PartFormInput({
  title = '', placeholder = '', value = '', onChange = () => {},
}) {
  return (
    <div className={styles.PartFormInput}>
      <p className={styles.PartFormInput__title}>{ title }</p>
      <label className={styles.PartFormInput__label}>
        <input
          className={styles.PartFormInput__input}
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </label>
    </div>
  );
}
