import axios from 'axios';
import cx from 'classnames';
import React, { useState } from 'react';

import { citiesForForm, formBackendUrl } from '../../../site-config';
import PartLazyImage from '../../part-lazy-image';

import IconCheckmarkBig from './assets/icon-checkmark-big.svg';
import IconCheckmark from './assets/icon-checkmark.svg';
import ImageAboutCompany from './assets/image-company-ratings.webp';
import ImageZeroPressure from './assets/image-zero-pressure.webp';
import IconShieldCheck from './assets/shield-check.png';
import PartFormInput from './part-form-input';
import { useRouter } from 'next/router';

import styles from './part-estimation-form.module.scss';

function getCookie(name) {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : '';
}

function getUTMValues() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return {
    utm_source: urlParams.get('utm_source') || '',
    utm_term: urlParams.get('utm_term') || '',
    utm_medium: urlParams.get('utm_medium') || '',
    utm_campaign: urlParams.get('utm_campaign') || '',
    utm_content: urlParams.get('utm_content') || '',
    fbclid: urlParams.get('fbclid') || '',
  };
}

export default function PartEstimationForm() {
  const [step, setStep] = useState(1);
  const [city, setCity] = useState('');
  const [postalCodeValue, setPostalCodeValue] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [windowsReplace, setWindowsReplace] = useState('');
  const [windowsAge, setWindowsAge] = useState('');
  const [next90days, setNext90days] = useState('');
  const router = useRouter();

  const sendData = () => {
    axios.post(formBackendUrl, {
      ...getUTMValues(),
      city,
      StreetAddress: `${city} ${postalCodeValue}`,
      fullName,
      email,
      phoneNumber,
      windowsReplace,
      windowsAge,
      next90days,
      initialvisit: new Date().toISOString().slice(0, 19).replace('T', ' '),
      contactSubmission: new Date().toISOString().slice(0, 19).replace('T', ' '),
      fbp: getCookie('_fbp'),
      fbc: getCookie('_fbc'),
    });
  };

  return (
    <div className={styles.formBox}>
      <form className={styles.form}>

        <div className={cx(styles.stepContainer, { [styles.stepContainer_current]: step === 1 }, styles.step1)}>
          <p className={styles.step1__title}>
            Get a
            <span> FREE no-obligation esti</span>
            mate
          </p>
          <div className={styles.step1__cityButtonsGrid}>
            {citiesForForm.map((city) => (
              <button
                key={city}
                className={styles.step1__cityButton}
                data-city={city}
                onClick={(e) => {
                  setCity(city);
                  setStep(2);
                  e.preventDefault();
                }}
                type="button"
              >
                {city}
              </button>
            ))}
          </div>
          <div className={styles.step1__postalCode}>
            <div className={styles.step1__postalCodeInputContainer}>
              <PartFormInput
                title="... Or Use Postal code"
                placeholder="E.g., H0H 0H0."
                value={postalCodeValue}
                onChange={(e) => setPostalCodeValue(e.target.value)}
              />
            </div>
            <button
              className={styles.step1__usePostalCodeButton}
              onClick={(e) => {
                setStep(2);
                e.preventDefault();
              }}
              type="button"
            >
              Use Postal Code
            </button>
            <div className={styles.step1__confidentialText}>
              <PartLazyImage
                src={IconShieldCheck}
                className={styles.step1__confidentialTextIcon}
              />
              <p>Confidential. Secure.</p>
            </div>
          </div>
        </div>

        <div className={cx(styles.stepContainer, { [styles.stepContainer_current]: step === 2 }, styles.step2)}>
          <div className={styles.formProgessBar}>
            <p className={styles.formProgessBar__title}>Step 2 of 3</p>
            <div className={styles.formProgessBar__stepper}>
              <div
                className={cx(
                  styles.formProgessBar__step,
                  styles.formProgessBar__step_passed,
                  styles.formProgessBar__step_passed1,
                )}
              />
              <div className={cx(
                styles.formProgessBar__stepMark,
                styles.formProgessBar__stepMark_1,
                styles.formProgessBar__stepMark_passed,
              )}
              >
                <div className={cx(
                  styles.formProgessBar__circle,
                  styles.formProgessBar__circle_passed,
                )}
                >
                  <IconCheckmark />
                </div>
                <div className={cx(
                  styles.formProgessBar__stepTitle,
                  styles.formProgessBar__stepTitle_passed,
                )}
                >
                  Free Estimate
                </div>
              </div>
              <div className={cx(
                styles.formProgessBar__stepMark,
                styles.formProgessBar__stepMark_2,
              )}
              >
                <div className={styles.formProgessBar__circle}>
                  <IconCheckmark />
                </div>
                <div className={styles.formProgessBar__stepTitle}>
                  +15% OFF
                </div>
              </div>
              <div className={cx(
                styles.formProgessBar__stepMark,
                styles.formProgessBar__stepMark_3,
              )}
              >
                <div className={styles.formProgessBar__circle}>
                  <IconCheckmark />
                </div>
                <div className={styles.formProgessBar__stepTitle}>
                  +10% OFF
                </div>
              </div>
            </div>
          </div>
          <div className={cx(
            styles.radioGroup,
            styles.radioGroup_windowsReplace,
          )}
          >
            <p className={styles.radioGroup__title}>How many windows and doors to replace?</p>
            <div className={styles.radioGroup__buttonsContainer}>
              <label className={styles.radioGroup__button}>
                <input
                  type="radio"
                  value="1 - 3"
                  name="windowsReplace"
                  onChange={(e) => setWindowsReplace(e.target.value)}
                />
                <span>1 - 3</span>
              </label>
              <label className={styles.radioGroup__button}>
                <input
                  type="radio"
                  value="4 - 8"
                  name="windowsReplace"
                  onChange={(e) => setWindowsReplace(e.target.value)}
                />
                <span>4 - 8</span>
              </label>
              <label className={styles.radioGroup__button}>
                <input
                  type="radio"
                  value="8+"
                  name="windowsReplace"
                  onChange={(e) => setWindowsReplace(e.target.value)}
                />
                <span>8+</span>
              </label>
            </div>
            <div className={styles.radioGroup__errorMessage} />
          </div>
          <div className={cx(
            styles.radioGroup,
            styles.radioGroup_windowsReplace,
          )}
          >
            <p className={styles.radioGroup__title}>How old are your current windows and doors?</p>
            <div className={styles.radioGroup__buttonsContainer}>
              <label className={styles.radioGroup__button}>
                <input
                  type="radio"
                  value="Less than 5 years"
                  name="windowsAge"
                  onChange={(e) => setWindowsAge(e.target.value)}
                />
                <span>Less than 5 years</span>
              </label>
              <label className={styles.radioGroup__button}>
                <input
                  type="radio"
                  value="5 to 10 years"
                  name="windowsAge"
                  onChange={(e) => setWindowsAge(e.target.value)}
                />
                <span>5 to 10 years</span>
              </label>
              <label className={styles.radioGroup__button}>
                <input
                  type="radio"
                  value="Over 10 years old"
                  name="windowsAge"
                  onChange={(e) => setWindowsAge(e.target.value)}
                />
                <span>Over 10 years old</span>
              </label>
            </div>
            <div className={styles.radioGroup__errorMessage} />
          </div>
          <div className={cx(
            styles.radioGroup,
            styles.radioGroup_estimation,
          )}
          >
            <p className={styles.radioGroup__title}>
              Are you looking to complete this project in the next 90
              days?
            </p>
            <div className={styles.radioGroup__buttonsContainer}>
              <label className={styles.radioGroup__button}>
                <input
                  type="radio"
                  value="Yes"
                  name="next90days"
                  onChange={(e) => setNext90days(e.target.value)}
                />
                <span>Yes</span>
              </label>
              <label className={styles.radioGroup__button}>
                <input
                  type="radio"
                  value="No"
                  name="next90days"
                  onChange={(e) => setNext90days(e.target.value)}
                />
                <span>No</span>
              </label>
            </div>
            <div className={styles.radioGroup__errorMessage} />
          </div>
          <button
            className={styles.nextButton}
            type="button"
            onClick={(e) => {
              setStep(3);
              e.preventDefault();
            }}
          >
            Next step
            </button>
          </div>

        <div className={cx(styles.stepContainer, { [styles.stepContainer_current]: step === 3 }, styles.step3)}>
          <div className={styles.formProgessBar}>
            <p className={styles.formProgessBar__title}>Step 3 of 3</p>
            <div className={styles.formProgessBar__stepper}>
              <div
                className={cx(
                  styles.formProgessBar__step,
                  styles.formProgessBar__step_passed,
                  styles.formProgessBar__step_passed2,
                )}
              />
              <div className={cx(
                styles.formProgessBar__stepMark,
                styles.formProgessBar__stepMark_1,
                styles.formProgessBar__stepMark_passed,
              )}
              >
                <div className={cx(
                  styles.formProgessBar__circle,
                  styles.formProgessBar__circle_passed,
                )}
                >
                  <IconCheckmark />
                </div>
                <div className={cx(
                  styles.formProgessBar__stepTitle,
                  styles.formProgessBar__stepTitle_passed,
                )}
                >
                  Free Estimate
                </div>
              </div>
              <div className={cx(
                styles.formProgessBar__stepMark,
                styles.formProgessBar__stepMark_2,
                styles.formProgessBar__stepMark_passed,
              )}
              >
                <div className={cx(
                  styles.formProgessBar__circle,
                  styles.formProgessBar__circle_passed,
                )}
                >
                  <IconCheckmark />
                </div>
                <div className={cx(
                  styles.formProgessBar__stepTitle,
                  styles.formProgessBar__stepTitle_passed,
                )}
                >
                  +15% OFF
                </div>
              </div>
              <div className={cx(
                styles.formProgessBar__stepMark,
                styles.formProgessBar__stepMark_3,
              )}
              >
                <div className={styles.formProgessBar__circle}>
                  <IconCheckmark />
                </div>
                <div className={styles.formProgessBar__stepTitle}>
                  +10% OFF
                </div>
              </div>
            </div>
          </div>

          <p className={styles.step3__title}>Who are we preparing this estimate for?</p>
          <div className={styles.step3__inputContainer}>
            <PartFormInput
              title="Full Name"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>

          <div className={styles.step3__inputContainer}>
            <PartFormInput
              title="Email Address"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className={styles.step3__inputContainer}>
            <PartFormInput
              title="Phone Number"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>

          <div className={styles.step3__imageAboutCompany}>
            <PartLazyImage
              src={ImageAboutCompany}
              alt=""
            />
          </div>

          <button
            className={styles.nextButton}
            type="button"
            onClick={(e) => {
              sendData();
              e.preventDefault();
              router.push('/thanks');
            }}
          >
            Send
          </button>
        </div>

        <div className={styles.step1__imageZeroPressure}>
          <PartLazyImage
            src={ImageZeroPressure}
            alt="pass the test to get a discount"
          />
        </div>
      </form>
    </div>
  );
}
